import { AssetsView, DeferredMapComponents, LiveView, MapProvider, mapCenterSelector, mapZoomLevelSelector } from '@griegconnect/krakentools-react-kmap'
import { useConfig } from '@griegconnect/krakentools-react-kraken-app'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { useMooringPlanServices } from '../../apis/mooring-plan-api/MooringPlanServices'
import { MooringPlanDto } from '../../apis/mooring-plan-api/dtos/MooringPlanDto'
import { usePortGatewayServices } from '../../apis/port-gateway-api/PortGatewayServices'
import { MooringPlanComponent } from './components/MooringPlanComponent'
import { LiveMapController } from "../map/LiveMapController"

type MooringPlanMapProps = {
  tenantId: string
  accessToken: string
}

export const getZoomLevel = (length: number | null) => {
  if (!length) return 5

  if (length < 25) return 18.2
  if (length < 50) return 18
  if (length < 100) return 16
  if (length < 200) return 15

  return 15
}

export const MooringPlanMap = ({ tenantId, accessToken }: MooringPlanMapProps) => {
  const config = useConfig()

  const mapIdentifierSlug = `port-go-map-mooring-${tenantId}`

  const params = useParams()
  const search = new URLSearchParams(params['*'])
  const showControlsQuery = search.get("showControls")
  const showControls = showControlsQuery === "true" || showControlsQuery === "1"

  const { getMooringPlan } = useMooringPlanServices()
  const { getQuayVisit } = usePortGatewayServices()

  const setZoomLevel = useSetRecoilState(mapZoomLevelSelector(mapIdentifierSlug))
  const setViewCenter = useSetRecoilState(mapCenterSelector(mapIdentifierSlug))

  const [mooringPlan, setMooringPlan] = useState<MooringPlanDto | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const init = async () => {
      const id = params.id

      const result = id ? await getMooringPlan(id) : false
      if (!result) return

      setViewCenter({
        lat: result.mooredPosition.lat,
        lon: result.mooredPosition.lon,
      })

      setZoomLevel(getZoomLevel(result.length))

      const vessel = await getQuayVisit(result.quayVisitId)

      setMooringPlan({
        ...result,
        vesselName: vessel ? vessel.vesselName : null,
        arrivalTime: vessel ? vessel.arrivalTime : null,
        departureTime: vessel ? vessel.departureTime : null,
      })
    }
    init().finally(() => setLoading(false))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return null
  }

  return (
    <MapProvider
      mapIdentifierSlug={mapIdentifierSlug}
      activeTenantId={tenantId}
      useStorage={false}
      monitorApiBaseUrl={config.api.monitorApiBaseUrl}
      getToken={async () => accessToken}
    >
      {showControls ? <>
        <LiveMapController tenantId={tenantId} />
        <DeferredMapComponents>
          <LiveView
            liveApiBaseUrl={config.api.positionsApiBaseUrl}
            tenantId={tenantId}
            defaultLayersToVisible
            enableDialogs
          />
          <AssetsView assetApiBaseUrl={config.api.assetsApiBaseUrl} tenantId={tenantId ?? null} enableDialogs />
          {mooringPlan && <MooringPlanComponent mooringPlan={mooringPlan} />}
        </DeferredMapComponents> 
      </> : null }
    </MapProvider>
  )
}
